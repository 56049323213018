<template>
	<div class="schedule">
		<!-- ##### Viral News Breadcumb Area Start ##### -->
		<div class="viral-news-breadcumb-area section-padding-50">
			<div class="container h-100">
				<div class="row h-100 align-items-center">
					<!-- Breadcumb Area -->
					<div class="col-12 col-md-4">
						<h3>定期点検</h3>
						<nav aria-label="breadcrumb">
							<ol class="breadcrumb">
								<li class="breadcrumb-item"><a href="/">Home</a></li>
								<li class="breadcrumb-item active" aria-current="page">
									定期点検
								</li>
							</ol>
						</nav>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Viral News Breadcumb Area End ##### -->

		<!-- ##### Contact Form Area Start ##### -->
		<div class="block section-padding-50">
			<div class="container">
				<div class="row">
					<div class="col-12 mb-5">
						<h3>定期点検</h3>
						<p>
							定期的に点検することでコンディションを維持しやすくなり、早期発見できることが重要です。<br />
							車検の2年サイクルは長過ぎる場合がありますので、車検で整備を一括するよりも、半年毎に分散して整備する方がコンディションレベルの平均値は高くなります。
						</p>
					</div>

					<!--<div class="col-12 col-lg-6 mb-5">
						<h3>6ヶ月点検</h3>
						<table class="table table-bordered">
							<tbody>
								<tr>
									<th class="headline">予約</th>
									<td>必要</td>
								</tr>
								<tr>
									<th class="headline">所要時間</th>
									<td>約3時間</td>
								</tr>
								<tr>
									<th class="headline">点検費用</th>
									<td>￥12,000（税抜）</td>
								</tr>
							</tbody>
						</table>
					</div>-->

					<div class="col-12 col-lg-6 mb-5">
						<h3>12ヶ月点検</h3>
						<table class="table table-bordered">
							<tbody>
								<tr>
									<th class="headline">予約</th>
									<td>必要</td>
								</tr>
								<tr>
									<th class="headline">点検費用</th>
									<td>￥23,800（税抜）</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div class="col-12 mb-5">
						<h3>法定24ヶ月点検および車検</h3>
						<p>
							法定24ヶ月点検(車検)については<a
								href="/vehicle_inspection"
								class="text"
								>車検ページ</a
							>をご覧ください。
						</p>

						<p class="text-center mt-5">
							<a
								href="https://form.run/@assist-kyoto"
								target="_blank"
								class="btn contact-btn"
								>お問い合わせはこちら<br />
								<small>お電話（075-582-7788）でもお気軽に</small></a
							>
						</p>
					</div>
				</div>
			</div>
		</div>
		<!-- ##### Contact Form Area End ##### -->
	</div>
</template>

<script>
export default {
	title: "定期点検",
	components: {},
};
</script>

<style scoped lang="scss">
ul {
	li {
		padding: 0.2rem;
	}
}
table {
	caption {
		font-size: 0.8rem;
	}
}
.table-bordered {
	thead {
		th {
			background-color: #f3f5f8;
		}
	}
	.headline {
		background-color: #f3f5f8;
	}
}
.table-primary {
	td,
	th {
		background-color: #daf3fb;
	}
}
.container {
	a.text {
		text-decoration: underline;
	}
}
</style>
